import React, { useState, useCallback, useEffect } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { IoArrowBackCircleSharp } from "react-icons/io5";
import RequestCategoryList from "./request-category-list";
import CustomModal from "./custom-modal"; // Make sure this is correctly imported
import SuccessLogo from "../images/info-circle-green.svg"; // Adjust path as necessary
import FailLogo from "../images/info-circle-red.svg"; // Adjust path as necessary
import DefaultLogo from "../images/info-circle-blue.svg"; // This is assumed, adjust if you're using a different or no default logo

const RequestCategoriesSection = ({ broker, id }) => {
	const [validated, setValidated] = useState(false);
	const [formData, setFormData] = useState({
		categories: broker.categories || [],
		updateNumber: broker.categoryUpdateNumber || 0,
	});
	const [showModal, setShowModal] = useState(false);
	const [modalContent, setModalContent] = useState({});

	useEffect(() => {
		setFormData({
			categories: broker?.categories || [],
			updateNumber: broker?.categoryUpdateNumber || 0,
		});
	}, [broker]);

	const handleChange = useCallback((event) => {
		const { name, value, checked, type } = event.target;

		setFormData((prevFormData) => ({
			...prevFormData,
			[name]: type === "checkbox" ? checked : value,
		}));
	}, []);

	const goBack = () => {
		window.history.back();
	};

	const updateBrokerCategories = async () => {
		const apiKey = process.env.GATSBY_MY_SECRET_API_KEY;
		try {
			const response = await fetch(
				"/.netlify/functions/updateBrokerCategories",
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						"x-api-key": apiKey,
					},
					body: JSON.stringify({
						id: id,
						categories: formData.categories,
						categoryUpdateNumber: formData.updateNumber + 1, // Example to generate a new number
					}),
				}
			);

			const responseData = await response.json();

			if (response.ok) {
				setModalContent({
					message: "Request sent successfully.",
					inviteFailed: false,
				});
			} else {
				setModalContent({
					message: "Failed to send request. Please try again.",
					inviteFailed: true,
				});
			}
		} catch (error) {
			setModalContent({
				message: "An error occurred. Please try again.",
				inviteFailed: true,
			});
		} finally {
			setShowModal(true);
		}
	};

	const handleSubmit = (event) => {
		const form = event.currentTarget;
		event.preventDefault();
		event.stopPropagation();

		updateBrokerCategories(); // Call the function to update categories
	};

	return (
		<section>
			<div style={{ minHeight: "100vh" }}>
				<Row className="justify-content-center">
					<Col className="">
						<div
							style={{
								boxShadow: "0px 3.75px 12px 0px rgba(8, 15, 52, 0.06)",
								borderRadius: "24px",
								minHeight: "90vh",
							}}
							className="bg-white p-5 position-relative"
						>
							<div className="">
								<a
									onClick={goBack}
									style={{ cursor: "pointer" }}
									className="black-link d-flex align-items-center position-absolute"
								>
									<IoArrowBackCircleSharp className="me-2 fs-2" /> Back
								</a>
							</div>
							<Row className="justify-content-center">
								<Col>
									<h1 className="mb-5 fs-2 text-center">
										Request New Categories
									</h1>
								</Col>
							</Row>
							<Form
								className={` w-90 mt-4 mx-auto`}
								noValidate
								validated={validated}
								onSubmit={handleSubmit}
							>
								<Row className="justify-content-center">
									<Col lg={6}>
										<RequestCategoryList
											formData={formData}
											setFormData={setFormData}
										/>
										<div className="mt-4 text-end ">
											<Button
												size="lg"
												className="gradient-button px-7 w-100 py-3 mt-3 text-uppercase"
												type="submit"
											>
												Send Request
											</Button>
										</div>
									</Col>
								</Row>
							</Form>
						</div>
					</Col>
				</Row>
			</div>
			<CustomModal show={showModal} onHide={() => setShowModal(false)}>
				<div className="d-block">
					{modalContent.inviteFailed === false ? (
						<div>
							<img
								src={SuccessLogo}
								alt="Success Logo"
								style={{ width: "70px" }}
							/>
							<p className="mt-3">{modalContent.message}</p>
						</div>
					) : (
						<div>
							<img src={FailLogo} alt="Fail Logo" style={{ width: "70px" }} />
							<p className="mt-3">{modalContent.message}</p>
						</div>
					)}
					<Button
						size="lg"
						className="gradient-button px-7 w-100 py-3 mt-3 text-uppercase"
						onClick={() => setShowModal(false)}
					>
						Close
					</Button>
				</div>
			</CustomModal>
		</section>
	);
};

export default RequestCategoriesSection;

import React, { useState, useCallback } from "react";
import { Button, Form, Row, Col } from "react-bootstrap";
import { Link, graphql, useStaticQuery } from "gatsby";

const CategoryComponentAddBroker = ({ formData, setFormData }) => {
	const data = useStaticQuery(graphql`
		query {
			allWpInsuranceCategory {
				nodes {
					id
					title
				}
			}
		}
	`);

	const dropdownCategories = data.allWpInsuranceCategory.nodes.map(
		(node) => node.title
	);
	const [selectedCategory, setSelectedCategory] = useState("");

	const handleSelectChange = (e) => {
		setSelectedCategory(e.target.value);
	};

	const handleAddCategory = () => {
		if (selectedCategory && !formData.categories.includes(selectedCategory)) {
			setFormData((prevFormData) => ({
				...prevFormData,
				categories: [...prevFormData.categories, selectedCategory],
			}));
			setSelectedCategory("");
		}
	};
	const handleChange = useCallback((event) => {
		const { name, value, checked, type } = event.target;

		setFormData((prevFormData) => ({
			...prevFormData,
			[name]: type === "checkbox" ? checked : value,
		}));
	}, []);

	const handleRemoveCategory = (categoryToRemove) => {
		setFormData((prevFormData) => ({
			...prevFormData,
			categories: prevFormData.categories.filter(
				(category) => category !== categoryToRemove
			),
		}));
	};

	return (
		<Form>
			<Row>
				<Col lg={6}>
					<Form.Group className="pb-3" controlId="subscriptionType">
						<Form.Label className="bold">Subscription Type*</Form.Label>
						<Form.Select
							name="subscriptionType"
							value={formData.subscriptionType}
							onChange={handleChange}
							required
							className="py-3"
							aria-label="Select subscription type"
						>
							<option default disabled value="">
								Choose...
							</option>
							<option value="Basic">Basic</option>
							<option value="Disabled">Disabled</option>
						</Form.Select>
						<Form.Control.Feedback type="invalid">
							Please select a subscription type.
						</Form.Control.Feedback>
					</Form.Group>
				</Col>
				<Col lg={6}>
					<Form.Label className="bold">Insurance Categories</Form.Label>
					<Form.Group
						controlId="categoryDropdown"
						style={{ display: "flex", marginBottom: "15px" }}
					>
						<Form.Select
							value={selectedCategory}
							onChange={handleSelectChange}
							className="p-3 me-3"
						>
							<option value="">Select a category</option>
							{dropdownCategories.map((category, index) => (
								<option key={index} value={category}>
									{category}
								</option>
							))}
						</Form.Select>
						<Button
							className="px-3"
							variant="primary"
							onClick={handleAddCategory}
						>
							Add
						</Button>
					</Form.Group>
				</Col>
			</Row>

			<div
				style={{
					background: "#F5F6FA",
					minHeight: "250px",
					borderRadius: "6px",
				}}
				className="p-3"
			>
				{formData.categories.map((category, index) => (
					<Button
						key={index}
						className="px-3"
						variant="outline-primary"
						style={{ margin: "5px", borderRadius: "100px" }}
						onClick={() => handleRemoveCategory(category)}
					>
						{category} <span>×</span>
					</Button>
				))}
			</div>
		</Form>
	);
};

export default CategoryComponentAddBroker;

import React, {
	Suspense,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from "react";
import {
	Form,
	Modal,
	Button,
	Container,
	Row,
	Col,
	ProgressBar,
	ToggleButton,
	ToggleButtonGroup,
} from "react-bootstrap";

import { ImCheckmark } from "react-icons/im";

import { v4 as uuidv4 } from "uuid";

import { Link } from "gatsby";
import { TbLetterF } from "react-icons/tb";
import CategoryComponent from "./category-component";
import CategoryComponentAddBroker from "./category-component-add-broker";
import { IoArrowBackCircleSharp } from "react-icons/io5";
import SuccessLogo from "../images/info-circle-green.svg";
import FailLogo from "../images/info-circle-red.svg";
import CustomModal from "./custom-modal";

const AddBrokerSection = () => {
	const [validated, setValidated] = useState(false);
	const [inviteFailed, setInviteFailed] = useState(false);
	const [show, setShow] = useState(false);
	const [message, setMessage] = useState("");
	const handleClose = () => setShow(false);
	const [formData, setFormData] = useState({
		// Initialize your form data fields

		preferences: ["telephone"],
		categories: [],
		subscriptionType: "",
	});

	const handleChange = useCallback((event) => {
		const { name, value, checked, type } = event.target;

		setFormData((prevFormData) => ({
			...prevFormData,
			[name]: type === "checkbox" ? checked : value,
		}));
	}, []);

	const goBack = () => {
		window.history.back();
	};
	const apiKey = process.env.GATSBY_MY_SECRET_API_KEY;
	const handleCreateBroker = async () => {
		// User data to be created
		const userData = {
			email: formData.email,
			password: uuidv4(),
			firstName: formData.firstName,
			lastName: formData.lastName,
			companyName: formData.companyName,
			subscription: formData.subscriptionType,
			categories: formData.categories,
		};

		try {
			const response = await fetch("/.netlify/functions/createBroker", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"x-api-key": apiKey, // Replace this with your actual client-side API key
				},
				body: JSON.stringify(userData),
			});

			if (!response.ok) {
				throw new Error(`Error: ${response.statusText}`);
			}

			const data = await response.json();

			setMessage("Invite Sent to Broker!");
			setInviteFailed(false);
			setShow(true);
			// Handle success (e.g., show a success message)
		} catch (error) {
			setMessage("Invite failed. Please try again.");
			setInviteFailed(true);
			setShow(true);
			// Handle error (e.g., show an error message)
		}
	};
	const handleSubmit = (event) => {
		const form = event.currentTarget;
		if (form.checkValidity() === true) {
			handleCreateBroker();
		}
		event.preventDefault();
		event.stopPropagation();
		setValidated(true);
	};

	return (
		<section>
			<div className="position-relative" style={{ minHeight: "100vh" }}>
				<CustomModal show={show} onHide={handleClose}>
					<div className="d-block">
						{inviteFailed === false && (
							<img
								className=" "
								src={SuccessLogo}
								alt="Success Logo"
								style={{
									width: "70px",
								}}
							/>
						)}{" "}
						{inviteFailed === true && (
							<img
								className=""
								src={FailLogo}
								alt="Fail Logo"
								style={{
									width: "70px",
								}}
							/>
						)}
						<p className="mt-3 Jakarta-Bold">{message}</p>
					</div>
					<div>
						{inviteFailed === false && (
							<Button
								as={Link}
								to="/admin/brokers"
								size="lg"
								className=" gradient-button px-7 w-100 py-3 mt-3 text-uppercase"
							>
								Go Back
							</Button>
						)}
						{inviteFailed === true && (
							<Button
								size="lg"
								className=" gradient-button px-7 w-100 py-3 mt-3 text-uppercase"
								onClick={handleClose}
							>
								Close
							</Button>
						)}
					</div>
				</CustomModal>

				<Row className="justify-content-center">
					<Col className="">
						<div
							style={{
								boxShadow: "0px 3.75px 12px 0px rgba(8, 15, 52, 0.06)",
								borderRadius: "24px",
							}}
							className="bg-white p-5 position-relative"
						>
							<div className="">
								<a
									onClick={goBack}
									style={{ cursor: "pointer" }}
									className="black-link d-flex align-items-center position-absolute"
								>
									<IoArrowBackCircleSharp className="me-2 fs-2" /> Back
								</a>
							</div>
							<Row className="justify-content-center">
								<Col>
									<h1 className="mb-5 fs-2 text-center">Add New Broker</h1>
								</Col>
							</Row>
							<Form
								className={` w-90 mt-4 mx-auto`}
								noValidate
								validated={validated}
								onSubmit={handleSubmit}
							>
								<div className=" ">
									<Row>
										<Col lg={6}>
											<Form.Group className="pb-3" controlId="firstName">
												<Form.Label className="bold">First Name *</Form.Label>
												<Form.Control
													placeholder="i.e. Zara"
													className="py-3"
													name="firstName"
													value={formData.firstName}
													onChange={handleChange}
													required
													type="text"
													isInvalid={validated && !formData.firstName}
												/>
												<Form.Control.Feedback type="invalid">
													Please provide a name.
												</Form.Control.Feedback>
											</Form.Group>
										</Col>
										<Col lg={6}>
											<Form.Group className="pb-3" controlId="lastName">
												<Form.Label className="bold">Last Name *</Form.Label>
												<Form.Control
													placeholder="i.e. Vornax"
													className="py-3"
													name="lastName"
													value={formData.lastName}
													onChange={handleChange}
													required
													type="text"
													isInvalid={validated && !formData.lastName}
												/>
												<Form.Control.Feedback type="invalid">
													Please provide a name.
												</Form.Control.Feedback>
											</Form.Group>
										</Col>
									</Row>
									<Row>
										<Col lg={6}>
											<Form.Group className="pb-3" controlId="companyName">
												<Form.Label className="bold">Company Name*</Form.Label>
												<Form.Control
													placeholder="Company Name"
													className="py-3"
													name="companyName"
													value={formData.companyName}
													onChange={handleChange}
													required
													type="text"
													isInvalid={validated && !formData.companyName}
												/>
												<Form.Control.Feedback type="invalid">
													Please provide a Company Name.
												</Form.Control.Feedback>
											</Form.Group>
										</Col>
										<Col lg={6}>
											<Form.Group className="pb-3" controlId="email">
												<Form.Label className="bold">Email *</Form.Label>
												<Form.Control
													placeholder="@email.com"
													name="email"
													className="py-3"
													value={formData.email}
													onChange={handleChange}
													required
													pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$"
													type="email"
													isInvalid={validated && !formData.email}
												/>
												<Form.Control.Feedback type="invalid">
													Please provide a valid email address.
												</Form.Control.Feedback>
											</Form.Group>
										</Col>
									</Row>
									<Row>
										<Col>
											<CategoryComponentAddBroker
												formData={formData}
												setFormData={setFormData}
											/>
										</Col>
									</Row>
								</div>

								<div className="mt-4 text-end ">
									<Button
										size="lg"
										className=" gradient-button px-7  py-3 mt-3 text-uppercase"
										type="submit"
									>
										SEND INVITE
									</Button>
								</div>
							</Form>
						</div>
					</Col>
				</Row>
			</div>
		</section>
	);
};

export default AddBrokerSection;
